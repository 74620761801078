// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-landingpages-control-your-source-code-management-tsx": () => import("./../../../src/components/landingpages/control-your-source-code-management.tsx" /* webpackChunkName: "component---src-components-landingpages-control-your-source-code-management-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-404-tsx": () => import("./../../../src/pages/blog/404.tsx" /* webpackChunkName: "component---src-pages-blog-404-tsx" */),
  "component---src-pages-cli-archive-tsx": () => import("./../../../src/pages/cli/archive.tsx" /* webpackChunkName: "component---src-pages-cli-archive-tsx" */),
  "component---src-pages-data-processing-tsx": () => import("./../../../src/pages/data-processing.tsx" /* webpackChunkName: "component---src-pages-data-processing-tsx" */),
  "component---src-pages-docs-404-tsx": () => import("./../../../src/pages/docs/404.tsx" /* webpackChunkName: "component---src-pages-docs-404-tsx" */),
  "component---src-pages-download-archive-tsx": () => import("./../../../src/pages/download/archive.tsx" /* webpackChunkName: "component---src-pages-download-archive-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plugins-tsx": () => import("./../../../src/pages/plugins.tsx" /* webpackChunkName: "component---src-pages-plugins-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-cli-install-tsx": () => import("./../../../src/templates/cli-install.tsx" /* webpackChunkName: "component---src-templates-cli-install-tsx" */),
  "component---src-templates-cli-tsx": () => import("./../../../src/templates/cli.tsx" /* webpackChunkName: "component---src-templates-cli-tsx" */),
  "component---src-templates-doc-tsx": () => import("./../../../src/templates/doc.tsx" /* webpackChunkName: "component---src-templates-doc-tsx" */),
  "component---src-templates-download-tsx": () => import("./../../../src/templates/download.tsx" /* webpackChunkName: "component---src-templates-download-tsx" */),
  "component---src-templates-plugin-docs-tsx": () => import("./../../../src/templates/plugin-docs.tsx" /* webpackChunkName: "component---src-templates-plugin-docs-tsx" */),
  "component---src-templates-plugin-install-tsx": () => import("./../../../src/templates/plugin-install.tsx" /* webpackChunkName: "component---src-templates-plugin-install-tsx" */),
  "component---src-templates-plugin-license-tsx": () => import("./../../../src/templates/plugin-license.tsx" /* webpackChunkName: "component---src-templates-plugin-license-tsx" */),
  "component---src-templates-plugin-no-docs-tsx": () => import("./../../../src/templates/plugin-no-docs.tsx" /* webpackChunkName: "component---src-templates-plugin-no-docs-tsx" */),
  "component---src-templates-plugin-releases-tsx": () => import("./../../../src/templates/plugin-releases.tsx" /* webpackChunkName: "component---src-templates-plugin-releases-tsx" */),
  "component---src-templates-plugin-tsx": () => import("./../../../src/templates/plugin.tsx" /* webpackChunkName: "component---src-templates-plugin-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-author-tsx": () => import("./../../../src/templates/posts-author.tsx" /* webpackChunkName: "component---src-templates-posts-author-tsx" */),
  "component---src-templates-posts-category-tsx": () => import("./../../../src/templates/posts-category.tsx" /* webpackChunkName: "component---src-templates-posts-category-tsx" */)
}

